import { useEffect } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { AGBLinkDe, AGBLinkEn } from '../helpers/agb-static-links'

const IndexPage = () => {
  const { locale } = useIntl()

  useEffect(() => {
    if (global.window) {
      window.location.href = locale === 'de' ? AGBLinkDe : AGBLinkEn
    }
  }, [])

  return null
}

export default IndexPage
